import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import { OptimisationButton } from "../OptimisationButton";
import OptimisationArrow from "@assets/icons/optimisationArrow";
import CustomInput from "@components/СustomInput";

interface OptimisationItemProps {
  title: string;
  initialValue: number;
  route: string;
}

export const OptimisationItem = ({
  title,
  initialValue,
  route,
}: OptimisationItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (location.pathname === route) {
      setIsOpen(true);
    }
  }, [location.pathname, route]);

  const handleSeeDetails = () => {
    navigate(route);
  };

  return (
    <div className={styles.optimisationContainer}>
      <button onClick={toggleOpen} className={styles.header}>
        <span className={styles.title}>{title}</span>
        <div className={`${styles.arrow} ${isOpen ? styles.rotated : ""}`}>
          <OptimisationArrow />
        </div>
      </button>
      {isOpen && (
        <div className={styles.content}>
          <CustomInput value={value} onChange={handleChange} blue={true} />
          <OptimisationButton onClick={handleSeeDetails} />
        </div>
      )}
    </div>
  );
};

export default OptimisationItem;
