import { Chart } from "chart.js";
import { useEffect } from "react";
import { tooltipArrayState } from "./tooltip-types";
import { MAX_RATING, MIN_AVAILABLE_RATING } from "./chart-data";

export const useMoveDot = (
  chartRef: React.RefObject<Chart<"radar", number[], string>>,
  isDragEnabled: boolean,
) => {
  useEffect(() => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    const canvas = chart.canvas;
    const productData = chart.data.datasets[0]?.data;
    if (!productData) return;

    const lengthInPixelToLine = 60;
    const ratio = lengthInPixelToLine / MAX_RATING;

    const handleMouseDown = (e: MouseEvent) => {
      if (!isDragEnabled) return;
      if (chart.tooltip?.opacity === 0) return;
      const index = chart.tooltip?.dataPoints[0]?.dataIndex ?? -1;
      const activeState = tooltipArrayState[index];
      if (!activeState) return;

      const startX = e.clientX;
      const startY = e.clientY;
      const startData = productData[index];

      const handleMouseMove = (e: MouseEvent) => {
        const offsetX = (startX - e.clientX) / ratio;
        const offsetY = (startY - e.clientY) / ratio;
        const offset =
          (activeState.dragInfo.direction === "x" ? offsetX : offsetY) *
          (activeState.dragInfo.reverse ? -1 : 1);
        const newData = startData + offset;

        productData[index] = Number(
          (newData < MIN_AVAILABLE_RATING
            ? MIN_AVAILABLE_RATING
            : newData > 5
              ? 5
              : newData
          ).toFixed(2),
        );
        chart.update();
      };

      const handleMouseUp = () => {
        canvas.removeEventListener("mousemove", handleMouseMove);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp, { once: true });
    };

    canvas.addEventListener("dragstart", () => false);
    canvas.addEventListener("mousedown", handleMouseDown);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
    };
  }, [chartRef, isDragEnabled]);
};