import React from "react";
import styles from "./styles.module.scss";
import CalendarButton from "@components/CalendarButton";
import { NavLink } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.headerContent}>My Product</h1>
      <div className={styles.buttons}>
        <CalendarButton />
        <NavLink to="/CRO-module" className={styles.button}>
          CRO MODULE
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
