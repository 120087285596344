import React, { useState } from "react";
import styles from "./styles.module.scss";
import TopSectionIcons from "../components/TopSectionIcons";
import SectionContainer from "../components/SectionContainer";
import { optimizationData } from "./mock";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";
import MySvgComponent from "./BackGroundFrame";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface OptimisationDetailsProps {
  handleRemove: () => void;
}

function OptimisationDetails({ handleRemove }: OptimisationDetailsProps) {
  const [isProblemClosed, setIsProblemClosed] = useState(false);
  const [data, setData] = useState(optimizationData);

  const handleToggle = () => {
    setIsProblemClosed(!isProblemClosed);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items);
  };

  return (
    <SectionContainer>
      <TopSectionIcons
        handleRemove={handleRemove}
        contentHidden={handleToggle}
        isContentClosed={!isProblemClosed}
      />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="optimizationList">
          {(provided) => (
            <div
              className={
                isProblemClosed ? styles.containerClosed : styles.containerOpen
              }
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {data.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className={styles.card}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={styles.header}>
                        <span className={styles.label}>{item.label}</span>
                        <WidgetIcon />
                      </div>
                      <div className={styles.content}>
                        <div
                          className={styles.backgroundFrame}
                          style={{
                            height: `${item.percentage}%`,
                          }}
                        >
                          <MySvgComponent
                            baseColor={item.color}
                            uniqueId={item.id}
                          />
                        </div>
                        {item.percentage > 0 ? (
                          <span className={styles.percentage}>
                            {item.percentage}%
                          </span>
                        ) : (
                          <span className={styles.text}>{item.text}</span>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </SectionContainer>
  );
}

export default OptimisationDetails;
