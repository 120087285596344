import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import inputArrow from "@assets/icons/inputArrow.svg";
import ProductSelectionModal from "@pages/Overview/components/ProductSelectionModal";
import { useProductStore } from "src/store/overviewProduct.state";
import { Product } from "@services/amazon/products/types";

interface ProductInputProps {
  label: string;
  isProductSelected?: boolean;
  selectType?: "your" | "competitor";
}

const ProductInput: React.FC<ProductInputProps> = ({
  label,
  isProductSelected,
  selectType = "your",
}) => {
  const {
    selectedProduct,
    selectedProductToCompare,
    setSelectedProduct,
    setSelectedProductToCompare,
  } = useProductStore();

  const [isModalOpen, setModalOpen] = useState(false);

  const product =
    selectType === "your" ? selectedProduct : selectedProductToCompare;

  useEffect(() => {
    if (!product && isProductSelected === false) {
      setModalOpen(true);
    }
  }, [product, isProductSelected]);

  const openModal = () => setModalOpen(true);

  const handleProductSelect = (selectedProduct: Product) => {
    if (selectType === "competitor") {
      setSelectedProductToCompare(selectedProduct);
    } else {
      setSelectedProduct(selectedProduct);
    }
    setModalOpen(false);
  };

  const getInputValue = (product: Product | null): string => {
    if (!product) return "";
    return product.item_name || product.title || "";
  };

  return (
    <div className={styles.inputBox}>
      <h4 className={styles.title}>{label}</h4>
      <button onClick={openModal} className={styles.inputContainer}>
        <input
          className={styles.inputText}
          value={getInputValue(product)}
          readOnly
        />
        <div className={styles.dropdownIcon}>
          <img src={inputArrow} width={12} height={12} alt="Dropdown Icon" />
        </div>
      </button>
      <ProductSelectionModal
        isOpen={isModalOpen}
        onSelect={handleProductSelect}
      />
    </div>
  );
};

export default ProductInput;
