import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import ProductInput from "@components/ProductInput";
import ProductCode from "@components/ProductCode";
import { useProductStore } from "src/store/overviewProduct.state";
import ProductSelectionModal from "../ProductSelectionModal";
import { Product } from "@services/amazon/products/types";

const ProductInfo: React.FC = () => {
  const { setSelectedProductToCompare } = useProductStore();
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const { selectedProduct } = useProductStore();
  const openModal = () => setModalOpen(true);

  const handleProductSelect = (selectedProduct: Product) => {
    setSelectedProductToCompare(selectedProduct);
    setModalOpen(false);
    navigate(`/${ROUTES.COMPARE}`);
  };

  return (
    <>
      <div className={styles.productInfo}>
        <div className={styles.productDetails}>
          <div className={styles.productCode}>
            <ProductInput label={"Your product"} isProductSelected={false} />
          </div>
          <div className={styles.compareButtonBox}>
            <button className={styles.compareButton} onClick={openModal}>
              COMPARE
            </button>
          </div>
        </div>
        <div className={styles.code}>
          <ProductCode code={selectedProduct?.asin ?? ""} />
        </div>
      </div>
      <ProductSelectionModal
        isOpen={isModalOpen}
        onSelect={handleProductSelect}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default ProductInfo;
