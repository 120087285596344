import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface VideoTitleProps {
  title: string;
  variant?: "default" | "small";
}

const VideoTitle: React.FC<VideoTitleProps> = ({ title, variant }) => {
  return (
    <h5
      className={classNames(
        styles.title,
        variant === "small" && styles.titleSmall,
      )}
    >
      {title}
    </h5>
  );
};

export default VideoTitle;
