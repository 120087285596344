export const labelClickPlugin = {
  id: "labelClick",
  afterEvent: (chart: any, args: any) => {
    const event = args.event;
    if (event.type !== "click") return;

    const { x, y } = event;
    const labels = chart.data.labels;
    const labelCoords = chart.scales.r._pointLabelItems;

    // Iterate through the label coordinates to find the clicked label
    for (let i = 0; i < labelCoords.length; i++) {
      const { x: labelX, y: labelY } = labelCoords[i];
      const radius = 10; // Adjust as needed for click area

      // Check if the click is within the radius of the label
      if (Math.sqrt((x - labelX) ** 2 + (y - labelY) ** 2) < radius) {
        if (chart.config.options.plugins?.onLabelClick) {
          chart.config.options.plugins.onLabelClick(i); // Call the handler
        }
        break;
      }
    }
  },
};