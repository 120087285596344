import React from "react";
import styles from "./styles.module.scss";
import Video from "@assets/video/templates/default.jobtemplate.mp4.480.mp4";
import VideoTitle from "../components/VideoTitle";
import VideoImg1 from "@assets/img/videoImg.png";
import VideoImg2 from "@assets/img/VideoImg2.png";

const VideoSection: React.FC = () => {
  return (
    <div className={styles.videoSlider}>
      <div className={styles.mainVideo}>
        <video className={styles.video} src={Video} controls />
        <VideoTitle title="Sugarbear Pro Hair Vitamins - Vegan Gummies for Luscious Hair and Nails" />
      </div>
      <div className={styles.sideVideos}>
        <div className={styles.sideVideo}>
          <img src={VideoImg1} alt="Img" />
          <VideoTitle variant="small" title="Sugarbear Pro Hair Vitamins - Vegan Gummies for Luscious Hair and Nails" />
        </div>
        <div className={styles.sideVideo}>
          <img src={VideoImg2} alt="Img" />
          <VideoTitle variant="small" title="Sugarbear Hair - Helps Maintain Beautiful Hair and Nails" />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
