import { ProductWithPictureUrl } from "@services/amazon/products/types";
import MockImg from "@assets/img/performance.png";

export const performance: ProductWithPictureUrl[] = [
  {
    picture_url:
      MockImg,
    displayName: "Antarctic Star 24 Bottle Wine Cooler",
    asin: "B07S39J8JX",
    dt_open: "2021-09-16T07:00:00.000Z",
    dt_updated: "2021-09-16T07:00:00.000Z",
    item_description:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar Digital Touch Panel Freestanding Wine Cellar for Red, White, Champagne or Sparkling Wine 24 Bottle",
    item_name: "Antarctic Star 24 Bottle Wine Cooler",
    title:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar...",
    rating: 3.6,
    link: "https://www.amazon.com/dp/B07S39J8JX",
    price: 189.99,
  },
  {
    title: "Ivation Compressor Wine Cooler",
    rating: 3.5,
    displayName: "Ivation 18 Bottle Compressor Wine Cooler",
    asin: "B07S39J8JX",
    dt_open: "2021-09-16T07:00:00.000Z",
    dt_updated: "2021-09-16T07:00:00.000Z",
    item_description:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar Digital Touch Panel Freestanding Wine Cellar for Red, White, Champagne or Sparkling Wine 24 Bottle",
    item_name: "Antarctic Star 24 Bottle Wine Cooler",
    link: "https://www.amazon.com/dp/B07S39J8JX",
    price: 189.99,
    picture_url:
      MockImg,
  },
  {
    title:
      "24 Bottle Compressor Wine Cooler Refrigerator, Small Freestanding Wine Fridge for Red, White and Ch...",
    rating: 4.3,
    displayName: "Antarctic Star 24 Bottle Wine Cooler",
    asin: "B07S39J8JX",
    dt_open: "2021-09-16T07:00:00.000Z",
    dt_updated: "2021-09-16T07:00:00.000Z",
    item_description:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar Digital Touch Panel Freestanding Wine Cellar for Red, White, Champagne or Sparkling Wine 24 Bottle",
    item_name: "Antarctic Star 24 Bottle Wine Cooler",
    link: "https://www.amazon.com/dp/B07S39J8JX",
    price: 189.99,
    picture_url:
      MockImg,
  },
  {
    title:
      "Ivation 18 Bottle Compressor Wine Cooler Refrigerator w/Lock, Large Freestanding Wine Cellar For Red,...",
    rating: 3.6,
    displayName: "Ivation 18 Bottle Compressor Wine Cooler",
    asin: "B07S39J8JX",
    dt_open: "2021-09-16T07:00:00.000Z",
    dt_updated: "2021-09-16T07:00:00.000Z",
    item_description:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar Digital Touch Panel Freestanding Wine Cellar for Red, White, Champagne or Sparkling Wine 24 Bottle",
    item_name: "Antarctic Star 24 Bottle Wine Cooler",
    link: "https://www.amazon.com/dp/B07S39J8JX",
    price: 189.99,
    picture_url:
      MockImg,
  },
  {
    title:
      "Schmécké 12 Bottle Compressor Wine Cooler Refrigerator w/Lock - Large Freestanding Wine Cellar Fo...",
    rating: 3.2,
    displayName: "Schmécké 12 Bottle Compressor Wine Cooler",
    asin: "B07S39J8JX",
    dt_open: "2021-09-16T07:00:00.000Z",
    dt_updated: "2021-09-16T07:00:00.000Z",
    item_description:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar Digital Touch Panel Freestanding Wine Cellar for Red, White, Champagne or Sparkling Wine 24 Bottle",
    item_name: "Antarctic Star 24 Bottle Wine Cooler",
    link: "https://www.amazon.com/dp/B07S39J8JX",
    price: 189.99,
    picture_url:
      MockImg,
  },
];
