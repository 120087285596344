import React from "react";
import styles from "./styles.module.scss";
import { useProductStore } from "src/store/overviewProduct.state";
import ProductCard from "./ProductCard";
import { mockData } from "./mock";

function Products() {
  const { selectedProduct, selectedProductToCompare } = useProductStore();

  return (
    <div className={styles.container}>
      <ProductCard
        product={selectedProduct}
        label="Your Product"
        selectType="your"
        mockData={mockData[0]}
      />

      <ProductCard
        product={selectedProductToCompare}
        label="Competitor"
        selectType="competitor"
        mockData={mockData[1]}
      />
    </div>
  );
}

export default Products;
