import React from "react";
import styles from "./styles.module.scss";
import ProductCode from "@components/ProductCode";
import CompareImg from "@assets/img/compare.svg";
import { ROUTES } from "src/router/routes";
import { useNavigate } from "react-router-dom";
import { ProductWithPictureUrl } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";

interface ProductCardProps {
  product: ProductWithPictureUrl;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const {
    setSelectedProductToCompare,
  } = useProductStore();

  const handleComparisonClick = () => {
    setSelectedProductToCompare(product);
    navigate(`/${ROUTES.COMPARE}`);
  };

  return (
    <div className={styles.productCard}>
      <img src={product.picture_url} alt={product.title} className={styles.productImage} />
      <div className={styles.productInfo}>
        <p className={styles.productTitle}>{product.title}</p>
        <ProductCode code={product.asin} />
        <p className={styles.productRating}>
          <span>⭐ {product.rating}</span>
        </p>
        <button
          className={styles.compareButton}
          onClick={handleComparisonClick}
        >
          COMPARE <img src={CompareImg} />
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
