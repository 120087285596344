import { ProductWithImageLink, ProductWithPictureUrl } from "@services/amazon/products/types";
import { create } from "zustand";

interface ProductsState {
  productOwn: ProductWithImageLink[];
  productPublic: ProductWithPictureUrl[];
  setProductOwn: (products: ProductWithImageLink[]) => void;
  setProductPublic: (products: ProductWithPictureUrl[]) => void;
}

export const useProductsStore = create<ProductsState>((set) => ({
  productOwn: [],
  productPublic: [],

  setProductOwn: (products) => set({ productOwn: products }),
  setProductPublic: (products) => set({ productPublic: products }),
}));
