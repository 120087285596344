export const optimizationData = [
  { id: 1,label: "Listing optimization", percentage: 75, color: "#418C3A" },
  { id: 2,label: "Photo optimization", percentage: 77, color: "#186C58" },
  { id: 3,label: "Video optimization", percentage: 20, color: "#342460" },
  {
    id: 4,label: "A+ content optimization",
    percentage: 0,
    color: "#FFFFFF",
    text: "Not optimized",
  },
];
